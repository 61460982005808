// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-digital-transformation-tsx": () => import("./../../../src/pages/digital-transformation.tsx" /* webpackChunkName: "component---src-pages-digital-transformation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-solutions-for-startups-tsx": () => import("./../../../src/pages/solutions-for-startups.tsx" /* webpackChunkName: "component---src-pages-solutions-for-startups-tsx" */),
  "component---src-pages-talents-forge-tsx": () => import("./../../../src/pages/talents-forge.tsx" /* webpackChunkName: "component---src-pages-talents-forge-tsx" */),
  "component---src-pages-team-leasing-tsx": () => import("./../../../src/pages/team-leasing.tsx" /* webpackChunkName: "component---src-pages-team-leasing-tsx" */),
  "component---src-pages-web-app-development-tsx": () => import("./../../../src/pages/web-app-development.tsx" /* webpackChunkName: "component---src-pages-web-app-development-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-case-studies-template-tsx": () => import("./../../../src/templates/case-studies-template.tsx" /* webpackChunkName: "component---src-templates-case-studies-template-tsx" */),
  "component---src-templates-list-item-tsx": () => import("./../../../src/templates/list-item.tsx" /* webpackChunkName: "component---src-templates-list-item-tsx" */),
  "component---src-templates-meetups-template-tsx": () => import("./../../../src/templates/meetups-template.tsx" /* webpackChunkName: "component---src-templates-meetups-template-tsx" */),
  "component---src-templates-products-template-tsx": () => import("./../../../src/templates/products-template.tsx" /* webpackChunkName: "component---src-templates-products-template-tsx" */)
}

