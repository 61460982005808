import React from "react"
import { ThemeProvider } from "styled-components"
import { myTheme } from "./src/my-theme.ts"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={myTheme}>{element}</ThemeProvider>
)

/*
export const onInitialClientRender = () => {
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date()
  ;(function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0]
    s1.async = true
    s1.src = "https://embed.tawk.to/60db064165b7290ac6387e00/1f9bp0sdj"
    s1.charset = "UTF-8"
    s1.setAttribute("crossorigin", "*")
    s0.parentNode.insertBefore(s1, s0)
  })()
}
*/
